import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from "@mui/material";
import { ReactComponent as ShowMoreIcon } from "../../../assets/icons/Show-more.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";
import DeteleTagModal from "./DeleteTagModal";
import ShowMoreTagModal from "./ShowMoreTagModal";
import EditTagModal from "./EditTagModal";
import { useQuery } from "react-query";
import { listTags } from "../../../services/tagService";

const TagsTable = () => {
  const [openShowMoreModal, setOpenShowMoreModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [tagName, setTagName] = useState("");
  const { data, isSuccess, isError, isLoading } = useQuery(
    "list-tags",
    listTags
  );
  if (isLoading) {
    return "...Loading";
  }

  return (
    <TableContainer component={Paper} sx={{ borderRadius: 5 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="table-header">
          <TableRow>
            <TableCell style={{ paddingLeft: "6%" }}>Tags</TableCell>
            <TableCell style={{ paddingLeft: "12%" }}>Description</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="table-body">
          {data?.data.tags.map((tag) => (
            <TableRow
              key={tag._id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ paddingLeft: "6%" }}
              >
                {tag.name}
              </TableCell>
              <TableCell style={{ paddingLeft: "12%" }}>
                {tag.description}
              </TableCell>
              <TableCell align="center">
                <div className="table-icons-container">
                  <IconButton>
                    <ShowMoreIcon
                      onClick={() => {
                        setOpenShowMoreModal(true);
                        setId(tag._id);
                      }}
                    />
                  </IconButton>
                  <IconButton>
                    <EditIcon
                      onClick={() => {
                        setOpenEditModal(true);
                        setId(tag._id);
                      }}
                    />
                  </IconButton>
                  <IconButton>
                    <DeleteIcon
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setId(tag._id);
                        setTagName(tag.name);
                      }}
                    />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
          {openShowMoreModal && (
            <ShowMoreTagModal
              openModal={openShowMoreModal}
              setOpenModal={setOpenShowMoreModal}
              id={id}
              setId={setId}
            />
          )}
          {openEditModal && (
            <EditTagModal
              openModal={openEditModal}
              setOpenModal={setOpenEditModal}
              id={id}
              setId={setId}
            />
          )}
          {openDeleteModal && (
            <DeteleTagModal
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
              id={id}
              setId={setId}
              tagName={tagName}
              setTagName={setTagName}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TagsTable;
