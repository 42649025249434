import React from "react";
import EditPersonForm from "./EditPersonForm";
import { Modal, Grid } from "@mui/material";
import { useQuery } from "react-query";
import { listTags } from "../../../services/tagService";
import { getPerson } from "../../../services/personService";

const EditPersonModal = ({ editModal, setEditModal }) => {
  const { data: tags, isLoading } = useQuery("list-tags", listTags);
  const { data: personData, isLoading: personLoading } = useQuery(
    ["get-person", editModal.id],
    () => getPerson(editModal.id)
  );

  const handleClose = () => {
    setEditModal({
      open: false,
      id: null
    });
  };

  if (isLoading || personLoading) {
    return "...is loading";
  }
  return (
    <Modal
      open={editModal.open}
      onClose={handleClose}
      style={{ overflow: "scroll", zIndex: 2 }}
    >
      <Grid
        container
        item
        xs={11}
        direction="column"
        className="add-person-modal-wrapper"
      >
        <h1 className="add-person-modal-title">Confirmer modification</h1>
        <EditPersonForm
          personData={personData.data.user}
          tags={tags.data.tags}
          handleClose={handleClose}
        />
      </Grid>
    </Modal>
  );
};

export default EditPersonModal;
