import api from "../config/api";

// signIn Admin
export const signInAdmin = async (data) => {
  return await api({
    method: "post",
    url: `/api/auth/admins/signin`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};
