import React, { useState } from "react";
import { Modal, Grid, TextField, Button } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getTag, updateTag } from "../../../services/tagService";

const ShowMoreTagModal = ({ openModal, setOpenModal, id, setId }) => {
  const [edit, setEdit] = useState(false);
  const [newTagForm, setNewTagForm] = useState({
    name: "",
    description: ""
  });
  const [errors, setErrors] = useState({});
  const { data: tagData } = useQuery(["get-tag", id], () => getTag(id));
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useMutation(updateTag, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-tags");
    }
  });
  const handleChange = (e) => {
    setNewTagForm({ ...newTagForm, [e.target.name]: e.target.value });
  };
  const handleEditTag = () => {
    const errorsTemp = {};

    if (!newTagForm.name) {
      errorsTemp.name = "*Ce champ est obligatoire";
    }
    if (!newTagForm.description) {
      errorsTemp.description = "*Ce champ est obligatoire";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      setNewTagForm({
        ...newTagForm,
        name: newTagForm.name,
        description: newTagForm.description
      });
      mutate({ id, data: newTagForm });
      setOpenModal(false);
      setId(null);
    }
  };
  const handleClose = () => {
    setOpenModal(false);
    setId(null);
    setEdit(false);
  };
  return (
    <Modal open={openModal} onClose={handleClose}>
      <Grid
        container
        item
        xs={4}
        direction="column"
        className="add-tag-modal-wrapper"
      >
        <h1 className="add-person-modal-title">Détail Tag</h1>
        <div className="add-tag-modal-content">
          <h4>Tag</h4>
          <Grid container item md={12} xs={12}>
            <TextField
              name="name"
              value={edit ? newTagForm.name : tagData?.data.tag.name}
              onChange={handleChange}
              placeholder={edit && "#Tagtagt2"}
              variant="outlined"
              disabled={!edit}
              className="text-field"
            />
            {errors.name && <p className="tag-error-msg">{errors.name}</p>}
          </Grid>
          <h4>Description</h4>
          <Grid container item md={12} xs={12}>
            <TextField
              name="description"
              value={
                edit ? newTagForm.description : tagData?.data.tag.description
              }
              onChange={handleChange}
              placeholder={edit && "Description"}
              variant="outlined"
              disabled={!edit}
              type="text"
              multiline
              rows={4}
              style={{ width: "100%" }}
            />
            {errors.description && (
              <p className="tag-error-msg">{errors.description}</p>
            )}
          </Grid>
        </div>

        {edit ? (
          <div className="add-tag-buttons">
            <Button className="cancel-button" onClick={handleClose}>
              Annuler
            </Button>
            <Button
              variant="contained"
              className="add-button"
              onClick={handleEditTag}
            >
              Enregistrer
            </Button>
          </div>
        ) : (
          <div className="show-more-tag-buttons">
            <Button
              variant="contained"
              className="add-button"
              onClick={() => setEdit(true)}
            >
              Modifier
            </Button>
          </div>
        )}
      </Grid>
    </Modal>
  );
};

export default ShowMoreTagModal;
