import React, { useState } from "react";
import { Modal, Grid, TextField, Button } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { createTag } from "../../../services/tagService";

const AddTagModal = ({ openModal, setOpenModal }) => {
  const handleClose = () => {
    setOpenModal(false);
  };
  const [tagForm, setTagForm] = useState({
    name: "",
    description: ""
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setTagForm({ ...tagForm, [e.target.name]: e.target.value });
  };
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useMutation(createTag, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-tags");
      setTagForm({ name: "", description: "" });
    }
  });

  const handlesubmitTag = () => {
    const errorsTemp = {};

    if (!tagForm.name) {
      errorsTemp.name = "*Ce champ est obligatoire";
    }
    if (!tagForm.description) {
      errorsTemp.description = "*Ce champ est obligatoire";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      setTagForm({
        ...tagForm,
        name: tagForm.name,
        description: tagForm.description
      });
      mutate(tagForm);
    }
  };
  return (
    <Modal open={openModal} onClose={handleClose}>
      <Grid
        container
        item
        xs={5}
        md={4}
        direction="column"
        className="add-tag-modal-wrapper"
      >
        <h1 className="add-person-modal-title">Ajouter un nouveau Tag</h1>
        <div className="add-tag-modal-content">
          <h4>Tag</h4>
          <Grid container item md={12} xs={12}>
            <TextField
              name="name"
              placeholder="#Tagtagt2"
              variant="outlined"
              onChange={handleChange}
              value={tagForm.name}
              className="text-field"
            />
            {errors.name && <p className="tag-error-msg">{errors.name}</p>}
          </Grid>
          <h4>Description</h4>
          <Grid container item md={12} xs={12}>
            <TextField
              name="description"
              variant="outlined"
              type="text"
              multiline
              rows={4}
              placeholder="Description"
              onChange={handleChange}
              value={tagForm.description}
              style={{ width: "100%" }}
            />
            {errors.description && (
              <p className="tag-error-msg">{errors.description}</p>
            )}
          </Grid>
        </div>
        <div className="add-tag-buttons">
          <Button className="cancel-button" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="contained"
            className="add-button"
            onClick={handlesubmitTag}
          >
            Ajouter
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default AddTagModal;
