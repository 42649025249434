import React, { useState } from "react";
import { OutlinedInput, InputAdornment, Button } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as PlusCircleIcon } from "../../../assets/icons/Plus-circle.svg";
import AddTagModal from "./AddTagModal";

const SettingsTags = () => {
  const [openAddTag, setOpenAddTag] = useState(false);
  return (
    <div className="setting-tags-wrapper">
      <OutlinedInput
        id="outlined"
        className="tags-search-input"
        placeholder="Rechercher ..."
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <Button
        variant="contained"
        className="add-tag-button"
        startIcon={<PlusCircleIcon />}
        onClick={() => setOpenAddTag(true)}
      >
        Ajouter nouveau tag
      </Button>

      {openAddTag && (
        <AddTagModal openModal={openAddTag} setOpenModal={setOpenAddTag} />
      )}
    </div>
  );
};

export default SettingsTags;
