import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from "@mui/material";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import DatePickerComponent from "../../../components/DatePicker";
import nationalityArr from "../../../json/nationality.json";
import { ReactComponent as DeleteChipIcon } from "../../../assets/icons/Delete-chip.svg";
import { ReactComponent as AddImageIcon } from "../../../assets/icons/Add-image.svg";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { listTags } from "../../../services/tagService";
import { createPerson } from "../../../services/personService";
import useDrivePicker from "react-google-drive-picker";

const AddPersonForm = ({ tags, handleClose }) => {
  const [openPicker, data, authResponse] = useDrivePicker();
  const blood = ["A+", "A-", "B+", "B-", "O+", "O-", "AB"];
  const gender = ["Homme", "Femme"];
  const [personForm, setPersonForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    emailPro: "",
    phoneNumber: "",
    birthdate: null,
    ethnicity: "",
    nationality: "",
    job: "",
    location: {
      address: "",
      latitude: "",
      longitude: "",
      city: "",
      state: "",
      country: "",
      zipCode: ""
    },
    website: "",
    facebook: "",
    instagram: "",
    twitter: "",
    gender: "",
    blood: "",
    weight: "",
    height: "",
    tags: [],
    images: []
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "address") {
      setPersonForm({
        ...personForm,
        location: { ...personForm.location, address: value }
      });
    } else if (name === "zipCode") {
      setPersonForm({
        ...personForm,
        location: { ...personForm.location, zipCode: value }
      });
    } else if (name === "city") {
      setPersonForm({
        ...personForm,
        location: { ...personForm.location, city: value }
      });
    } else if (name === "state") {
      setPersonForm({
        ...personForm,
        location: { ...personForm.location, state: value }
      });
    } else if (name === "country") {
      setPersonForm({
        ...personForm,
        location: { ...personForm.location, country: value }
      });
    } else if (name === "latitude") {
      setPersonForm({
        ...personForm,
        location: { ...personForm.location, latitude: value }
      });
    } else if (name === "longitude") {
      setPersonForm({
        ...personForm,
        location: { ...personForm.location, longitude: value }
      });
    } else {
      setPersonForm({ ...personForm, [name]: value });
    }
  };
  const handleChangeTags = (e) => {
    const value = e.target.value;
    setPersonForm({
      ...personForm,
      tags: e.target.value
    });
    // setSelectedTags(
    //   value.map((el) =>
    //     tags?.data.tags.find((elt) => (elt._id === el ? elt : null))
    //   )
    // );
  };
  const handleDeleteChip = (id) => {
    setPersonForm({
      ...personForm,
      tags: personForm.tags.filter((el) => el !== id)
    });
  };
  //UPLOAD IMAGE IN GOOGLE DRIVE
  const SCOPE = "https://www.googleapis.com/auth/drive.file";
  const discoveryUrl =
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
  useEffect(() => {
    var script = document.createElement("script");
    script.onload = handleClientLoad;
    script.src = "https://apis.google.com/js/api.js";
    document.body.appendChild(script);
  }, []);
  const handleClientLoad = () => {
    window.gapi.load("client:auth2", initClient);
  };
  const [googleAuth, setGoogleAuth] = useState("");
  const [token, setToken] = useState("");
  const initClient = () => {
    try {
      window.gapi.client
        .init({
          apiKey: "AIzaSyB6CO2-noCe65ICqEAqo7pIpACZuHK86xo",
          clientId:
            "655067900584-6uv1f7ki2sno03oultaed3cpr3gl7759.apps.googleusercontent.com",
          plugin_name: "chat",
          scope: SCOPE,
          discoveryDocs: [discoveryUrl]
        })
        .then(() => {
          setGoogleAuth(window.gapi.auth2.getAuthInstance());
          googleAuth.isSignedIn?.listen(updateSigninStatus);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const updateSigninStatus = () => {
    setSigninStatus();
  };
  const setSigninStatus = () => {
    var user = this.state.googleAuth.currentUser.get();
    var token = window.gapi.auth.getToken().access_token;
    console.log("token", token);
    console.log(user);
    var isAuthorized = user.hasGrantedScopes(SCOPE);
    if (isAuthorized) {
      setToken(window.gapi.auth.getToken().access_token);
    }
  };
  const imageHandleChange = () => {
    openPicker({
      clientId:
        "759130959018-3jv4rtevub2codj7t54v7pn267k7j7gb.apps.googleusercontent.com",
      developerKey: "AIzaSyAt5v3UwBy-yHJHLx8Zlopef1pQKxjawKw",
      viewId: "DOCS_IMAGES",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true
    });
  };
  useEffect(() => {
    if (data) {
      data.docs.map((i) => console.log(i));
      data.docs.map((el) => {
        let url = `https://drive.google.com/uc?export=view&id=${el.id}`;
        setPersonForm((prev) => ({
          ...prev,
          images: [...prev.images, url]
        }));
      });
    }
  }, [data]);
  const handleDeleteImage = (i) => {
    let arr = personForm.images;
    setPersonForm({
      ...personForm,
      images: arr.filter((el) => personForm.images.indexOf(el) !== i)
    });
  };

  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError } = useMutation(createPerson, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-persons");
      handleClose();
    }
  });
  const handleSubmitPerson = () => {
    const errorsTemp = {};
    if (!personForm.firstName) {
      errorsTemp.firstName = "*Ce champ est obligatoire";
    }
    if (!personForm.lastName) {
      errorsTemp.lastName = "*Ce champ est obligatoire";
    }
    if (!personForm.email) {
      errorsTemp.email = "*Ce champ est obligatoire";
    }
    if (!personForm.emailPro) {
      errorsTemp.emailPro = "*Ce champ est obligatoire";
    }
    if (!personForm.phoneNumber) {
      errorsTemp.phoneNumber = "*Ce champ est obligatoire";
    }
    if (!personForm.birthdate) {
      errorsTemp.birthdate = "*Ce champ est obligatoire";
    }
    if (!personForm.ethnicity) {
      errorsTemp.ethnicity = "*Ce champ est obligatoire";
    }
    if (!personForm.nationality) {
      errorsTemp.nationality = "*Ce champ est obligatoire";
    }
    if (!personForm.job) {
      errorsTemp.job = "*Ce champ est obligatoire";
    }
    if (!personForm.location.address) {
      errorsTemp.address = "*Ce champ est obligatoire";
    }
    if (!personForm.location.latitude || isNaN(personForm.location.latitude)) {
      errorsTemp.latitude = "*Entrer une valide latitude";
    }
    if (!personForm.location.longitude || isNaN(personForm.location.latitude)) {
      errorsTemp.longitude = "*Entrer une valide longitude";
    }
    if (!personForm.location.city) {
      errorsTemp.city = "*Ce champ est obligatoire";
    }
    if (!personForm.location.state) {
      errorsTemp.state = "*Ce champ est obligatoire";
    }
    if (!personForm.location.country) {
      errorsTemp.country = "*Ce champ est obligatoire";
    }
    if (!personForm.location.zipCode || isNaN(personForm.location.zipCode)) {
      errorsTemp.zipCode = "*Entrer un valide code postale";
    }
    if (!personForm.website) {
      errorsTemp.website = "*Ce champ est obligatoire";
    }
    if (!personForm.facebook) {
      errorsTemp.facebook = "*Ce champ est obligatoire";
    }
    if (!personForm.instagram) {
      errorsTemp.instagram = "*Ce champ est obligatoire";
    }
    if (!personForm.twitter) {
      errorsTemp.twitter = "*Ce champ est obligatoire";
    }
    if (!personForm.gender) {
      errorsTemp.gender = "*Ce champ est obligatoire";
    }
    if (!personForm.blood) {
      errorsTemp.blood = "*Ce champ est obligatoire";
    }
    if (!personForm.weight) {
      errorsTemp.weight = "*Ce champ est obligatoire";
    }
    if (!personForm.height) {
      errorsTemp.height = "*Ce champ est obligatoire";
    }
    if (personForm.tags.length === 0) {
      errorsTemp.tags = "*Ce champ est obligatoire";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      mutate(personForm);
    }
  };
  return (
    <>
      <div className="add-person-modal-content">
        <Grid
          container
          item
          md={6}
          xs={6}
          direction="column"
          className="left-side"
        >
          <h4>Générales</h4>
          <div>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Nom"
                variant="outlined"
                className="text-field"
                name="lastName"
                value={personForm.lastName}
                onChange={handleChange}
              />
              {errors.lastName && (
                <p className="tag-error-msg">{errors.lastName}</p>
              )}
            </Grid>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Prénom"
                variant="outlined"
                className="text-field"
                name="firstName"
                value={personForm.firstName}
                onChange={handleChange}
              />
              {errors.firstName && (
                <p className="tag-error-msg">{errors.firstName}</p>
              )}
            </Grid>
          </div>
          <div>
            <Grid container item md={4} xs={4}>
              <TextField
                label="Email"
                variant="outlined"
                className="text-field"
                name="email"
                value={personForm.email}
                onChange={handleChange}
              />
              {errors.email && <p className="tag-error-msg">{errors.email}</p>}
            </Grid>
            <Grid container item md={8} xs={8}>
              <TextField
                label="Email Pro"
                variant="outlined"
                className="text-field"
                name="emailPro"
                value={personForm.emailPro}
                onChange={handleChange}
              />
              {errors.emailPro && (
                <p className="tag-error-msg">{errors.emailPro}</p>
              )}
            </Grid>
          </div>
          <div>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Numero de telephone"
                variant="outlined"
                className="text-field"
                name="phoneNumber"
                value={personForm.phoneNumber}
                onChange={handleChange}
              />
              {errors.phoneNumber && (
                <p className="tag-error-msg">{errors.phoneNumber}</p>
              )}
            </Grid>
            <Grid container item md={6} xs={6}>
              <DatePickerComponent
                personForm={personForm}
                setPersonForm={setPersonForm}
                errors={errors}
              />
            </Grid>
          </div>
          <div>
            <Grid container item md={6} xs={6}>
              <FormControl sx={{ m: 1, width: "100%", margin: 0 }}>
                <InputLabel id="demo-select-small">Nationalité</InputLabel>
                <Select
                  className="select-input"
                  id="demo-select-small"
                  label="Nationalité"
                  name="nationality"
                  value={personForm.nationality}
                  onChange={handleChange}
                >
                  {nationalityArr.map((el, i) => (
                    <MenuItem key={i} value={el.nationality}>
                      {el.nationality}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.nationality && (
                <p className="tag-error-msg">{errors.nationality}</p>
              )}
            </Grid>
            <Grid container item md={6} xs={6}>
              <FormControl sx={{ m: 1, width: "100%", margin: 0 }}>
                <InputLabel id="demo-select-small">Race</InputLabel>
                <Select
                  className="select-input"
                  id="demo-select-small"
                  label="Race"
                  name="ethnicity"
                  value={personForm.ethnicity}
                  onChange={handleChange}
                >
                  {nationalityArr.map((el, i) => (
                    <MenuItem key={i} value={el.nationality}>
                      {el.nationality}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.ethnicity && (
                <p className="tag-error-msg">{errors.ethnicity}</p>
              )}
            </Grid>
          </div>
          <div>
            <Grid container item md={12} xs={12}>
              <TextField
                label="Emploi"
                variant="outlined"
                className="text-field"
                name="job"
                value={personForm.job}
                onChange={handleChange}
              />
              {errors.job && <p className="tag-error-msg">{errors.job}</p>}
            </Grid>
          </div>
          <div>
            <Grid container item md={8} xs={8}>
              <TextField
                label="Rue"
                variant="outlined"
                className="text-field"
                name="address"
                value={personForm.location.address}
                onChange={handleChange}
              />
              {errors.address && (
                <p className="tag-error-msg">{errors.address}</p>
              )}
            </Grid>
            <Grid container item md={4} xs={4}>
              <TextField
                label="Code postale"
                variant="outlined"
                className="text-field"
                name="zipCode"
                value={personForm.location.zipCode}
                onChange={handleChange}
              />
              {errors.zipCode && (
                <p className="tag-error-msg">{errors.zipCode}</p>
              )}
            </Grid>
          </div>
          <div>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Ville"
                variant="outlined"
                className="text-field"
                name="city"
                value={personForm.location.city}
                onChange={handleChange}
              />
              {errors.city && <p className="tag-error-msg">{errors.city}</p>}
            </Grid>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Gouvernorat"
                variant="outlined"
                className="text-field"
                name="state"
                value={personForm.location.state}
                onChange={handleChange}
              />
              {errors.state && <p className="tag-error-msg">{errors.state}</p>}
            </Grid>
          </div>
          <div>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Latitude"
                variant="outlined"
                className="text-field"
                name="latitude"
                value={personForm.location.latitude}
                onChange={handleChange}
              />
              {errors.latitude && (
                <p className="tag-error-msg">{errors.latitude}</p>
              )}
            </Grid>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Longitude"
                variant="outlined"
                className="text-field"
                name="longitude"
                value={personForm.location.longitude}
                onChange={handleChange}
              />
              {errors.longitude && (
                <p className="tag-error-msg">{errors.longitude}</p>
              )}
            </Grid>
          </div>
          <div>
            <Grid container item md={12} xs={12}>
              <TextField
                label="Pays"
                variant="outlined"
                className="text-field"
                name="country"
                value={personForm.location.country}
                onChange={handleChange}
              />
              {errors.country && (
                <p className="tag-error-msg">{errors.country}</p>
              )}
            </Grid>
          </div>
          <h4>Contacts</h4>
          <div>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Site web"
                variant="outlined"
                className="text-field"
                name="website"
                value={personForm.website}
                onChange={handleChange}
              />
              {errors.website && (
                <p className="tag-error-msg">{errors.website}</p>
              )}
            </Grid>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Twitter"
                variant="outlined"
                className="text-field"
                name="twitter"
                value={personForm.twitter}
                onChange={handleChange}
              />
              {errors.twitter && (
                <p className="tag-error-msg">{errors.twitter}</p>
              )}
            </Grid>
          </div>
          <div>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Facebook"
                variant="outlined"
                className="text-field"
                name="facebook"
                value={personForm.facebook}
                onChange={handleChange}
              />
              {errors.facebook && (
                <p className="tag-error-msg">{errors.facebook}</p>
              )}
            </Grid>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Instagram"
                variant="outlined"
                className="text-field"
                name="instagram"
                value={personForm.instagram}
                onChange={handleChange}
              />
              {errors.instagram && (
                <p className="tag-error-msg">{errors.instagram}</p>
              )}
            </Grid>
          </div>
        </Grid>
        <Grid
          container
          item
          md={6}
          xs={6}
          direction="column"
          className="right-side"
        >
          <h4>Infos supplementaires</h4>
          <div>
            <Grid container item md={6} xs={6}>
              <FormControl sx={{ m: 1, width: "100%", margin: 0 }}>
                <InputLabel id="demo-select-small">Sexe</InputLabel>
                <Select
                  className="select-input"
                  id="demo-select-small"
                  label="Sexe"
                  name="gender"
                  value={personForm.gender}
                  onChange={handleChange}
                >
                  {gender.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.gender && (
                <p className="tag-error-msg">{errors.gender}</p>
              )}
            </Grid>
            <Grid container item md={6} xs={6}>
              <FormControl sx={{ m: 1, width: "100%", margin: 0 }}>
                <InputLabel id="demo-select-small">Type de sang</InputLabel>
                <Select
                  className="select-input"
                  id="demo-select-small"
                  label="Type de sang"
                  name="blood"
                  value={personForm.blood}
                  onChange={handleChange}
                >
                  {blood.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.blood && <p className="tag-error-msg">{errors.blood}</p>}
            </Grid>
          </div>
          <div>
            <Grid container item md={6} xs={6}>
              <TextField
                label="Le poids"
                variant="outlined"
                className="text-field"
                name="weight"
                value={personForm.weight}
                onChange={handleChange}
              />
              {errors.weight && (
                <p className="tag-error-msg">{errors.weight}</p>
              )}
            </Grid>
            <Grid container item md={6} xs={6}>
              <TextField
                label="La taille"
                variant="outlined"
                className="text-field"
                name="height"
                value={personForm.height}
                onChange={handleChange}
              />
              {errors.height && (
                <p className="tag-error-msg">{errors.height}</p>
              )}
            </Grid>
          </div>

          <h4>Tags</h4>
          <div>
            <Grid
              container
              item
              md={12}
              xs={12}
              style={{ flexDirection: "column" }}
            >
              <FormControl sx={{ m: 1, width: "100%", margin: 0 }}>
                <InputLabel id="demo-select-small">#Tags</InputLabel>
                <Select
                  className="select-input"
                  id="demo-select-small"
                  label="#Tags"
                  value={personForm.tags}
                  onChange={handleChangeTags}
                  multiple
                >
                  {tags.map((el, i) => (
                    <MenuItem key={i} value={el._id}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.tags && <p className="tag-error-msg">{errors.tags}</p>}
              <div className="chip-container">
                {personForm.tags.map((el, i) => (
                  <div key={i} className="tag-chip">
                    <p>{tags.find((tag) => tag._id === el)?.name}</p>
                    <IconButton onClick={() => handleDeleteChip(el)}>
                      <DeleteChipIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
            </Grid>
          </div>
          <h4>Image</h4>
          <div className="images-wrapper">
            {personForm.images.map((el, i) => (
              <div key={i} className="image-container">
                <div className="image-box">
                  <img alt="" src={el} />
                </div>
                <IconButton className="delete-image">
                  <CancelTwoToneIcon onClick={() => handleDeleteImage(i)} />
                </IconButton>
              </div>
            ))}
            <div className="add-image-container">
              <div className="inner-add-image-container">
                <IconButton onClick={() => imageHandleChange()}>
                  <AddImageIcon />
                </IconButton>
              </div>

              <p>Ajouter une image</p>
            </div>
          </div>
        </Grid>
      </div>
      <div className="add-person-buttons">
        <Button className="cancel-button" onClick={handleClose}>
          Annuler
        </Button>
        <Button
          variant="contained"
          className="add-button"
          onClick={handleSubmitPerson}
        >
          Ajouter
        </Button>
      </div>
    </>
  );
};

export default AddPersonForm;
