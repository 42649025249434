import React from "react";
import { Modal, Grid, Button } from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete-modal.svg";
import { useMutation, useQueryClient } from "react-query";
import { deleteTag } from "../../../services/tagService";

const DeteleTagModal = ({
  openModal,
  setOpenModal,
  id,
  setId,
  tagName,
  setTagName
}) => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError, isLoading } = useMutation(deleteTag, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-tags");
    }
  });
  const handleDeleteTag = () => {
    mutate(id);
    handleClose();
  };
  const handleClose = () => {
    setOpenModal(false);
    setId(null);
    setTagName("");
  };
  return (
    <Modal open={openModal} onClose={handleClose}>
      <Grid
        container
        item
        xs={4}
        direction="column"
        className="delete-modal-content"
      >
        <DeleteIcon />
        <h1 className="delete-modal-title">Supprimer Tag</h1>
        <h3 className="delete-modal-desc">
          Voulez vous vraiment supprimer “{tagName}” ?
        </h3>
        <div className="delete-modal-buttons">
          <Button className="cancel-button" onClick={handleClose}>
            Annuler
          </Button>
          <Button className="delete-button" onClick={handleDeleteTag}>
            Supprimer
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default DeteleTagModal;
