import React, { useState, useEffect } from "react";
import "../../styles/login.css";
import {
  OutlinedInput,
  Checkbox,
  Button,
  InputAdornment,
  IconButton
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ReactComponent as LogoIcon } from "../../assets/icons/Logo.svg";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { signInAdmin } from "../../services/authService";

const Login = () => {
  let navigate = useNavigate();
  const { mutate, isSuccess, data, isError, reset } = useMutation(signInAdmin);
  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("token", data.data.token);
      navigate("/dashboard");
      reset();
    }
    if (isError) {
      reset();
    }
  }, [isSuccess, isError]);
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };
  const submit = () => {
    const errorsTemp = {};

    if (!loginForm.email) {
      errorsTemp.email = "*Ce champ est obligatoire";
    }
    if (!loginForm.password) {
      errorsTemp.password = "*Ce champ est obligatoire";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      setLoginForm({
        ...loginForm,
        email: loginForm.email,
        password: loginForm.password
      });
      mutate(loginForm);
    }
  };
  return (
    <div className="login-wrapper">
      <div className="login-content">
        <LogoIcon />
        <h1 className="login-title">Bienvenu</h1>
        <h4 className="login-descreption">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </h4>
        <div className="login-inputs-container">
          <OutlinedInput
            id="outlined"
            name="email"
            placeholder="Identifiant"
            value={loginForm.email}
            onChange={handleChange}
            className="login-input"
          />
          {errors.email && <p className="login-error-msg">{errors.email}</p>}
          <OutlinedInput
            id="outlined"
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Mot de passe"
            value={loginForm.password}
            onChange={handleChange}
            className="login-input"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={(e) => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.password && (
            <p className="login-error-msg">{errors.password}</p>
          )}
        </div>
        <div className="login-checkbox-container">
          <Checkbox
            className="login-checkbox"
            defaultChecked
            // checked={checked}
            // onChange={handleChange}
          />
          <p>Sauvegarder ma session</p>
        </div>
        <Button variant="contained" className="login-button" onClick={submit}>
          Se connecté
        </Button>
      </div>
    </div>
  );
};

export default Login;
