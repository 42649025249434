import React from "react";
import "../styles/header.css";
import { Avatar, IconButton } from "@mui/material";
import avatar from "../assets/images/avatar.png";
import { ReactComponent as ArrowIcon } from "../assets/icons/Arrow.svg";

const HeaderDashboard = ({ title, description }) => {
  return (
    <div className="header">
      <div className="header-desc">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="header-avatar">
        <Avatar alt="Remy Sharp" src={avatar} sx={{ width: 64, height: 64 }} />
        <IconButton>
          <ArrowIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default HeaderDashboard;
