import React from "react";
import { Modal, Grid, Button } from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete-modal.svg";
import { useMutation, useQueryClient } from "react-query";
import { deletePerson } from "../../../services/personService";

const DetelePersonModal = ({ deleteModal, setDeleteMoreModal }) => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError, isLoading } = useMutation(deletePerson, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-persons");
    }
  });
  const handleDeletePerson = () => {
    mutate(deleteModal.id);
    handleClose();
  };
  const handleClose = () => {
    setDeleteMoreModal({
      open: false,
      id: null,
      name: ""
    });
  };
  return (
    <Modal open={deleteModal.open} onClose={handleClose}>
      <Grid
        container
        item
        xs={4}
        direction="column"
        className="delete-modal-content"
      >
        <DeleteIcon />
        <h1 className="delete-modal-title">Supprimer Person</h1>
        <h3 className="delete-modal-desc">
          Voulez vous vraiment supprimer “ {deleteModal.name}” ?
        </h3>
        <div className="delete-modal-buttons">
          <Button className="cancel-button" onClick={handleClose}>
            Annuler
          </Button>
          <Button className="delete-button" onClick={handleDeletePerson}>
            Supprimer
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default DetelePersonModal;
