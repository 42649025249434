import React, { useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Box,
  SvgIcon,
  OutlinedInput,
  InputAdornment,
  Button
} from "@mui/material";
import { ReactComponent as SlidersIcon } from "../../../assets/icons/Sliders.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/Upload.svg";
import { ReactComponent as PlusCircleIcon } from "../../../assets/icons/Plus-circle.svg";
import AddPersonModal from "./AddPersonModal";
const SettingsDashboard = () => {
  const [openAddPerson, setOpenAddPerson] = useState(false);
  return (
    <div className="setting-dashboard-wrapper">
      <FormControl className="settings-dashboard-filter">
        <Select
          defaultValue="Filter"
          // onChange={handleChange}
          displayEmpty
          renderValue={(value) => {
            return (
              <Box sx={{ display: "flex", gap: 1 }}>
                <SvgIcon style={{ marginRight: 24 }}>
                  <SlidersIcon />
                </SvgIcon>
                {value}
              </Box>
            );
          }}
        >
          <MenuItem value="Filter">
            <em>Filter</em>
          </MenuItem>
          <MenuItem value="Ten">Ten</MenuItem>
          <MenuItem value="Twenty">Twenty</MenuItem>
          <MenuItem value="Thirty">Thirty</MenuItem>
        </Select>
      </FormControl>
      <OutlinedInput
        id="outlined"
        className="search-input"
        placeholder="Rechercher ..."
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <Button
        variant="contained"
        className="export-button"
        startIcon={<UploadIcon />}
      >
        Export CSV
      </Button>
      <Button
        variant="contained"
        className="add-person-button"
        startIcon={<PlusCircleIcon />}
        onClick={() => setOpenAddPerson(true)}
      >
        Ajouter Personne
      </Button>
      {openAddPerson && (
        <AddPersonModal
          openModal={openAddPerson}
          setOpenModal={setOpenAddPerson}
        />
      )}
    </div>
  );
};

export default SettingsDashboard;
