import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from "@mui/material";
import { ReactComponent as ShowMoreIcon } from "../../../assets/icons/Show-more.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";
import ShowMorePersonModal from "./ShowMorePersonModal";
import EditPersonModal from "./EditPersonModal";
import DetelePersonModal from "./DetelePersonModal";
import { useQuery } from "react-query";
import { listPersons } from "../../../services/personService";

const PersonTable = () => {
  const [editModal, setEditModal] = useState({ open: false, id: null });
  const [showMoreModal, setShowMoreModal] = useState({ open: false, id: null });
  const [deleteModal, setDeleteMoreModal] = useState({
    open: false,
    id: null,
    name: ""
  });
  const { data, isSuccess, isError, isLoading } = useQuery(
    "list-persons",
    listPersons
  );
  if (isLoading) {
    return "...Loading";
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="table-header">
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Numero</TableCell>
            <TableCell>Sexe</TableCell>
            <TableCell>Race</TableCell>
            <TableCell>Nationalité</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="table-body">
          {data?.data.users.map((person, i) => (
            <TableRow
              key={i}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {person.firstName}&nbsp;
                {person.lastName}
              </TableCell>
              <TableCell>{person.email}</TableCell>
              <TableCell>{person.phoneNumber}</TableCell>
              <TableCell>{person.gender}</TableCell>
              <TableCell>{person.ethnicity}</TableCell>
              <TableCell>{person.nationality}</TableCell>
              <TableCell align="center">
                <div>
                  <IconButton
                    onClick={() => {
                      setShowMoreModal({
                        open: true,
                        id: person._id
                      });
                    }}
                  >
                    <ShowMoreIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setEditModal({
                        open: true,
                        id: person._id
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteMoreModal({
                        open: true,
                        id: person._id,
                        name: person.firstName
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {showMoreModal.open && (
        <ShowMorePersonModal
          showMoreModal={showMoreModal}
          setShowMoreModal={setShowMoreModal}
        />
      )}
      {editModal.open && (
        <EditPersonModal editModal={editModal} setEditModal={setEditModal} />
      )}
      {deleteModal.open && (
        <DetelePersonModal
          deleteModal={deleteModal}
          setDeleteMoreModal={setDeleteMoreModal}
          // id={id}
          // setId={setId}
          // personName={personName}
          // setPersonName={setPersonName}
        />
      )}
    </TableContainer>
  );
};
export default PersonTable;
