import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DatePickerComponent = ({
  personForm,
  setPersonForm,
  personData,
  newPersonForm,
  setNewPersonForm,
  errors
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Date de naissance"
        name="birthdate"
        value={personData ? newPersonForm?.birthdate : personForm.birthdate}
        onChange={(newValue) => {
          personData
            ? setNewPersonForm({ ...newPersonForm, birthdate: newValue })
            : setPersonForm({ ...personForm, birthdate: newValue });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className="text-field"
            InputLabelProps={{
              shrink: Boolean(personData)
            }}
            disabled={Boolean(personData)}
          />
        )}
      />
      {errors.birthdate && <p className="tag-error-msg">{errors.birthdate}</p>}
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
