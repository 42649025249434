import React from "react";
import { Modal, Grid } from "@mui/material";

import { useQuery } from "react-query";
import { getPerson } from "../../../services/personService";
import { listTags } from "../../../services/tagService";
import ShowMorePersonForm from "./ShowMorePersonForm";

const ShowMorePersonModal = ({ showMoreModal, setShowMoreModal }) => {
  const { data: tags, isLoading } = useQuery("list-tags", listTags);
  const { data: personData, isLoading: personLoading } = useQuery(
    ["get-person", showMoreModal.id],
    () => getPerson(showMoreModal.id)
  );

  // useEffect(() => {
  //   delete personData?.data.user._id;
  //   delete personData?.data.user.role;
  //   delete personData?.data.user.isDeleted;
  //   delete personData?.data.user.createdAt;
  //   delete personData?.data.user.updatedAt;
  //   setNewPersonForm({ ...personData?.data.user });
  // }, [personData?.data.user]);

  const handleClose = () => {
    setShowMoreModal({
      open: false,
      id: null
    });
  };
  if (isLoading || personLoading) {
    return "...is loading";
  }
  return (
    <Modal
      open={showMoreModal.open}
      onClose={handleClose}
      style={{ overflow: "scroll", zIndex: 2 }}
    >
      <Grid
        container
        item
        xs={11}
        direction="column"
        className="add-person-modal-wrapper"
      >
        <h1 className="add-person-modal-title">Détails Personne</h1>
        <ShowMorePersonForm
          personData={personData.data.user}
          tags={tags.data.tags}
          handleClose={handleClose}
        />
      </Grid>
    </Modal>
  );
};

export default ShowMorePersonModal;
