import React from "react";
import "../../styles/tags.css";
import SideBar from "../../layout/SideBar";
import Header from "../../components/Header";
import SettingsTags from "./components/SettingsTags";
import TagsTable from "./components/TagsTable";

const Tags = () => {
  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      <div className="tags-wrapper">
        <Header
          title="Liste des Tags"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        />
        <SettingsTags />
        <TagsTable />
      </div>
    </div>
  );
};

export default Tags;
