import React, { useState } from "react";
import {
  Modal,
  Grid,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { updateTag } from "../../../services/tagService";

const EditTagModal = ({ openModal, setOpenModal, id, setId }) => {
  const [updateTagForm, setUpdateTagForm] = useState({
    name: "",
    description: ""
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setUpdateTagForm({ ...updateTagForm, [e.target.name]: e.target.value });
  };
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError, isLoading } = useMutation(updateTag, {
    onSuccess: () => {
      queryClient.invalidateQueries("list-tags");
      setUpdateTagForm({ name: "", description: "" });
    }
  });
  const handleUpdateTag = () => {
    const errorsTemp = {};

    if (!updateTagForm.name) {
      errorsTemp.name = "*Ce champ est obligatoire";
    }
    if (!updateTagForm.description) {
      errorsTemp.description = "*Ce champ est obligatoire";
    }
    setErrors(errorsTemp);
    if (Object.keys(errorsTemp).length === 0) {
      setUpdateTagForm({
        ...updateTagForm,
        name: updateTagForm.name,
        description: updateTagForm.description
      });
      mutate({ id, data: updateTagForm });
    }
  };
  const handleClose = () => {
    setOpenModal(false);
    setId(null);
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Grid
        container
        item
        xs={4}
        direction="column"
        className="add-tag-modal-wrapper"
      >
        <h1 className="add-person-modal-title">Modifier le Tag</h1>
        <div className="add-tag-modal-content">
          <h4>Tag</h4>
          <Grid container item md={12} xs={12}>
            <TextField
              name="name"
              onChange={handleChange}
              value={updateTagForm.name}
              placeholder="#Tagtagt2"
              variant="outlined"
              className="text-field"
            />
            {errors.name && <p className="tag-error-msg">{errors.name}</p>}
          </Grid>
          <h4>Description</h4>
          <Grid container item md={12} xs={12}>
            <TextField
              name="description"
              onChange={handleChange}
              value={updateTagForm.description}
              placeholder="Description"
              variant="outlined"
              type="text"
              multiline
              rows={4}
              style={{ width: "100%" }}
            />
            {errors.description && (
              <p className="tag-error-msg">{errors.description}</p>
            )}
          </Grid>
        </div>
        <div className="add-tag-buttons">
          <Button className="cancel-button" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="contained"
            className="add-button"
            onClick={handleUpdateTag}
            // disabled={isLoading}
          >
            {/* {true ? (
              <CircularProgress sx={{ color: "background.paper" }} />
            ) : ( */}
            Enregistrer
            {/* )} */}
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};

export default EditTagModal;
