import React from "react";
import "../../styles/dashboard.css";
import SideBar from "../../layout/SideBar";
import Header from "../../components/Header";
import SettingsDashboard from "./components/SettingsDashboard";
import PersonTable from "./components/PersonTable";

const Dashboard = () => {
  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      <div className="dashboard-wrapper">
        <Header
          title="Dashboard - Liste des Personnes"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        />
        <SettingsDashboard />
        <PersonTable />
      </div>
    </div>
  );
};

export default Dashboard;
