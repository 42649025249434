import React from "react";
import { Modal, Grid } from "@mui/material";
import { useQuery } from "react-query";
import { listTags } from "../../../services/tagService";
import AddPersonForm from "./AddPersonForm";

const AddPersonModal = ({ openModal, setOpenModal }) => {
  const { data: tags, isLoading } = useQuery("list-tags", listTags);
  const handleClose = () => {
    setOpenModal(false);
  };
  if (isLoading) {
    return "...is loading";
  }
  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      style={{ overflow: "scroll", zIndex: 2 }}
    >
      <Grid
        container
        item
        xs={11}
        direction="column"
        className="add-person-modal-wrapper"
      >
        <h1 className="add-person-modal-title">Ajouter Personne</h1>
        <AddPersonForm tags={tags.data.tags} handleClose={handleClose} />
      </Grid>
    </Modal>
  );
};

export default AddPersonModal;
