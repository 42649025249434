import api from "../config/api";
// create Tag
export const createPerson = (data) => {
  const token = localStorage.getItem("token");
  return api({
    method: "post",
    url: `/api/users`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// list all persons
export const listPersons = () => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/users`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// get a person
export const getPerson = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "get",
    url: `/api/users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};

// update a person
export const updatePerson = ({ id, data }) => {
  const token = localStorage.getItem("token");
  return api({
    method: "put",
    url: `/api/users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data
  }).then((res) => res.data);
};

// delete a person
export const deletePerson = (id) => {
  const token = localStorage.getItem("token");
  return api({
    method: "delete",
    url: `/api/users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  }).then((res) => res.data);
};
