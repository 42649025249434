import React, { useState } from "react";
import "../styles/sideBar.css";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as SideBarLogoIcon } from "../assets/icons/SideBar-logo.svg";
import { ReactComponent as ActivityIcon } from "../assets/icons/Activity.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/Dashboard.svg";
import { ReactComponent as TagsIcon } from "../assets/icons/Tags.svg";
import { ReactComponent as LogOutIcon } from "../assets/icons/Logout.svg";
import { useNavigate } from "react-router-dom";

const SideBar = () => {
  let navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const [activeStep, setActiveStep] = useState(1);

  return (
    <div className="sidebar-wrapper">
      <div className="header-sidebar">
        <SideBarLogoIcon />
        <IconButton>
          <ActivityIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setActiveStep(1);
            navigate("/dashboard");
          }}
        >
          <DashboardIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setActiveStep(2);
            navigate("/tags");
          }}
        >
          <TagsIcon />
        </IconButton>
      </div>
      <div>
        <IconButton onClick={logOut}>
          <LogOutIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default SideBar;
